body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Poppins', sans-serif !important;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.responsiveSwiper {
  width: 100%;
}

.responsiveSwiper .swiper-slide {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
}

.responsiveSwiper .swiper-pagination {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.responsiveSwiper .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  display: inline-block;
  background-color: #E5E5E5; 
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.responsiveSwiper .swiper-pagination-bullet-active {
  background-color: #3162AC; 
  transform: scale(1.2); 
}

/*Hamburger Menu*/
.css-10hburv-MuiTypography-root {
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 32px !important;
  color:#fff !important;
  line-height: 32px !important;
  padding-top: 5px !important;
}

.MuiDrawer-paper{
  top: 60px !important;
  left: 0px;
  box-shadow: none !important;
  background-color: #3162AC !important;
}

.MuiModal-backdrop{
  background-color: #3162AC !important;
} 

.css-1hskriy{
  width:100% !important;
}
/**/